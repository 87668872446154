.details-list-container {
    width: 350px;
    height: calc(100vh - 210px);
}
.details-list-header {
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.details-list-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.details-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.details-list-items-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 275px);
    overflow: auto;
}
.details-list-item {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.details-list-item-footer-container {
    display: flex;
    width: 100%;
    height: 35px;
    text-align: center;
}
.details-list-item-footer-name-container,
.details-list-item-footer-cover-container,
.details-list-item-footer-intro-container,
.details-list-item-footer-categories-container,
.details-list-item-footer-status-container {
    flex: 1;
    font-size: 25px;
}

.details-list-item-update-name-chip-holder,
.details-list-item-update-intro-chip-holder {
    text-align: right;
    margin-top: 20px;
}
.details-list-item-update-name-chip {

}

.pointer {
    cursor: pointer;
}

@media (max-width: 763px) {
    .details-list-container {
        width: 100%;
        height: calc(100vh - 150px);
    }
    .details-list-header {
        width: 100%;
    }
    .details-list-items-container {
        height: calc(100vh - 300px)
    }
}