.todo-list-container {
    position: relative;
    width: 350px;
    height: calc(100vh - 210px);
}
.todo-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.todo-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.todo-list-header-info-icon-holder {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.todo-list-header-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}
.todo-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.todo-list-attendee-list-container {
    padding: 10px;
    display: flex;
    overflow: auto; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    white-space: nowrap; /* Corrected property */
    z-index: 2;
 }
.todo-list-items-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 370px);
    overflow: auto;
}
.todo-list-item {
    position: relative;
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.todo-add-new-item-container {
    height: calc(100vh - 280px);
    overflow: auto;
}

@media (max-width: 768px) {
    .todo-list-container {
        width: 100%;
        height: calc(100vh - 150px);
    }
    .todo-list-header {
        width: 100%;
    }
    .todo-list-items-container {
        height: calc(100vh - 385px);
    }
}