
.planning-div {
    position: relative;
    overflow: hidden;
}

.planning-div-summary-container {
    display: flex;
    justify-content: space-between; /* Pushes title to the left and other elements to the right */
    align-items: center; /* Vertically centers items if they have different heights */
    padding: 10px 10px 0 10px;
}

.planning-div-summary-event-title {
    font-size: 13px;
    font-weight: 500;
    color: #10318f;
    border: 1px solid #428ce2;
    padding: 2px;
    border-radius: 5px;
}

.planning-div-summary-right {
    display: flex;
    align-items: center; /* Vertically aligns the Chip and dates */
}
.planning-div-summary-name {
    margin-right: 10px;
}
.planning-div-summary-location {
    margin-right: 10px;
}
.planning-div-summary-dates {
    font-size: 13px;
}

.planning-grid-search {

}

.planning-grid-content {

}

.planning-options-list {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
}

.planning-event-routes-holder {

}

.planning-event-routes-extended-holder {
    height: calc(100vh - 205px);
}
.planning-whiteboard-list-holder {
    display: none;
}

.planning-google-map-recommendations-holder {
    width: 100%;
    display: flex;
    height: calc(100vh - 280px);
}

.planning-map-area-container {
    position: relative;
    flex: 1;
    padding: 0;
    margin-right: 10px;
    height: calc(100vh - 265px);
}

.planning-recommendations-list {
    width: 300px;
    justify-content: flex-end;
    padding: 10px 0;
    height: calc(100vh - 265px);
}


@media (max-width: 768px) {
    .planning-event-main-body-holder {

    }
    .planning-event-routes-holder {
        overflow: auto;
    }
    .planning-google-map-recommendations-holder-container {
        display: block;
        overflow: auto;
    }
    .planning-google-map-recommendations-holder {
        width: 100%;
        display: block;
        height: auto;
        max-height: fit-content;
    }
    .planning-google-map {
        padding: 0 20px;
        margin-right: 0;
        width: calc(100vw - 40px);
    }
    .planning-recommendations-list {
        position: relative;
        width: calc(100vw - 40px);
        padding: 20px;
    }
    .planning-concierge-header-holder {
        width: calc(100vw - 40px) !important;
    }
}





