

.messages-list-container {
    position: relative;
    width: 350px;
    height: calc(100vh - 210px);
}
.messages-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.messages-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.messages-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.messages-list-items-container{
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 370px);
    overflow: auto;
}

.messages-list-items-footer-container {
    position: absolute;
    bottom: 0;
    width: 94%;
    border-top: 1px solid #ccc;
    padding: 10px;
    background-color: white;
}

@media (max-width: 768px) {
    .messages-list-container{
        width: 100%;
        height: calc(100vh - 150px);
    }
    .messages-list-timetable-list-container {
        max-width: 100%;
    }
    .messages-list-items-container{
        height: calc(100vh - 400px);
    }
    .messages-list-items-footer-container {
        bottom: 100px;
    }
}