
.photos-list-container {
    width: 350px;
    margin-bottom: 10px;
    height: calc(100vh - 210px);
}
.photos-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.photos-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.photos-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.photos-list-attendee-list-container {
    padding: 10px;
    display: flex;
    overflow: auto; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    white-space: nowrap; /* Corrected property */
    z-index: 2;
}
.photos-list-items-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 370px);
    overflow: auto;
}
.photos-add-new-item-container {
    height: calc(100vh - 260px);
    padding: 0 10px;
    overflow: auto;
}

@media (max-width: 768px) {
    .photos-list-container {
        width: 100%;
        height: calc(100vh - 150px);
    }
    .photos-list-header {
        width: 100%;
    }
    .photos-list-items-container {
        height: calc(100vh - 385px);
    }
}