



.lodging-list-container,
.lodging-suggestions-list-container {
    position: relative;
    width: 350px;
    height: calc(100vh - 210px);
}
.lodging-list-header,
.lodging-suggestions-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.lodging-list-header-text,
.lodging-suggestions-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.lodging-list-header-info-icon-holder {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.lodging-list-header-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}
.lodging-list-header-chip-holder,
.lodging-suggestions-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.lodging-list-timetable-list-container {
    max-width: 350px;
    padding: 10px;
    display: flex;
    overflow: auto; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    white-space: nowrap; /* Corrected property */
    z-index: 2;
}

.lodging-suggestions-price-range-display-container {
    position: fixed;
    width: 350px;
    font-size: 11px;
    text-align: right;
    backdrop-filter: blur(6px);
    background-color: rgba(249, 250, 251, 0.8);
    z-index: 2;
}
.lodging-suggestions-price-range-container {
    position: fixed;
    padding-top: 45px;
    width: 350px;
    backdrop-filter: blur(6px);
    background-color: rgba(249, 250, 251, 0.8);
    z-index: 1;
}
.lodging-list-items-container{
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 370px);
    overflow: auto;
}
.lodging-suggestions-list-items-container {
    width: 350px;
    padding-top: 70px;
    height: calc(100vh - 330px);
    overflow: auto;
}
.lodging-list-item {
    position: relative;
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.lodging-list-item-checkin-date {
    position: absolute;
    top: 0;
    left: 0;
}
.lodging-list-item-checkout-date {
    position: absolute;
    bottom: 0;
    left: 0;
}
.lodging-list-item-stay-dates-holder {
    display: flex;
    font-size: 12px;
}
.lodging-list-item-stay-dates {
    flex: 1;
}
.lodging-list-item-date-count {
    flex: 1;
    text-align: right;
}
.lodging-list-item-lodging-name {

}
.lodging-list-item-lodging-address {

}

.lodging-suggestions-list-item {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.lodging-list-item-header-chip-holder,
.lodging-suggestions-list-item-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
    position: absolute;
    top: 0;
    right: -10px;
}
.lodging-add-list-items-container,
.lodging-suggestions-add-list-items-container {
    padding-top: 60px;
}
.lodging-add-list-item,
.lodging-suggestions-add-list-item {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.lodging-suggestions-add-list-item-photos {
    max-height: 200px;
    overflow: auto;
}
.horizontal-scroll-container {
    display: flex; /* Align items in a row */
    max-height: 200px;
    overflow-x: auto; /* Allow horizontal scrolling */
    gap: 8px; /* Add spacing between images */
    padding: 8px 0; /* Optional padding for better spacing */
    scrollbar-width: thin; /* For modern browsers, thin scrollbar */
}

.horizontal-scroll-container::-webkit-scrollbar {
    height: 8px; /* Adjust scrollbar height */
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
    background: #ccc; /* Style the scrollbar thumb */
    border-radius: 4px;
}

.horizontal-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #888; /* Darker on hover */
}

.photo {
    max-width: 150px; /* Adjust image width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: subtle shadow */
    flex-shrink: 0; /* Prevent images from shrinking */
}

.lodging-suggestions-add-list-item-footer-container {
    display: flex;
    width: 100%;
    height: 35px;
    text-align: center;
}
.lodging-suggestions-add-list-item-footer-dates-container,
.lodging-suggestions-add-list-item-footer-location-container,
.lodging-suggestions-add-list-item-footer-occupants-container,
.lodging-suggestions-add-list-item-footer-costs-container,
.lodging-suggestions-add-list-item-footer-details-container,
.lodging-suggestions-add-list-item-footer-documents-container {
    font-size: 25px;
}
.lodging-suggestions-add-list-item-footer-dates-container {
    flex: 1;
    cursor:pointer;
}
.lodging-suggestions-add-list-item-footer-dates-holder {
    padding: 10px 0;
    font-size: 12px;
    text-align: right;
}
.lodging-suggestions-add-list-item-footer-dates-checkin,
.lodging-suggestions-add-list-item-footer-dates-checkout {
    padding: 5px 0;
    align-items: center;
}
.lodging-suggestions-add-list-item-footer-dates-checkin-input,
.lodging-suggestions-add-list-item-footer-dates-checkout-input {

}

.lodging-suggestions-add-list-item-footer-location-container {
    flex: 1;
    cursor:pointer;
}

.lodging-suggestions-add-list-item-footer-occupants-container {
    flex: 1;
    cursor:pointer;
}
.lodging-suggestions-add-list-item-footer-costs-container {
    flex: 1;
    cursor:pointer;
}
.lodging-suggestions-add-list-item-footer-costs-tab-amount {
    font-size: 12px;
}
.lodging-suggestions-add-list-item-footer-costs-header {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: right;
}
.lodging-suggestions-add-list-item-footer-costs-total-holder {
    display: flex;
    margin-bottom: 20px;
}
.lodging-suggestions-add-list-item-footer-costs-total {
    flex: 1;
    font-size: 14px;
    text-align: right;
    font-weight: 600;
}
.lodging-suggestions-add-list-item-footer-costs-override-holder {

}
.lodging-suggestions-add-list-item-footer-costs-override-input-holder{

}
.lodging-suggestions-add-list-item-footer-cost-assignment-holder {
    padding-left: 5px;
    margin-top: 10px;
}
.lodging-suggestions-add-list-item-footer-cost-assignment-no-attendees {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.lodging-suggestions-add-list-item-footer-cost-assignment-toggle-holder {
    text-align: right;
}
.lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder {
    font-size: 12px;
}
.lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item {
    font-size: 12px;
    margin: 10px 0;
}
.lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost {
    font-size: 12px;
}
.lodging-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost {

}
.lodging-suggestions-add-list-item-footer-cost-assignment-unassigned-holder {
    display: flex;
}
.lodging-suggestions-add-list-item-footer-cost-assignment-unassigned-amount {
    font-size: 12px;
}

.lodging-suggestions-add-list-item-footer-details-container {
    flex: 1;
    cursor:pointer;
}


@media (max-width: 768px) {
    .lodging-list-container{
        width: 100%;
        height: calc(100vh - 150px);
    }
    .lodging-list-timetable-list-container {
        max-width: 100%;
    }
    .lodging-list-items-container{
        height: calc(100vh - 376px);
    }
}