
.receipts-list-container {
    width: 350px;
    height: calc(100vh - 210px);
}
.receipts-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.receipts-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.receipts-list-header-info-icon-holder {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.receipts-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.receipts-list-attendee-list-container {
    padding: 10px;
    display: flex;
    overflow: auto; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    white-space: nowrap; /* Corrected property */
    z-index: 2;
}
.receipts-list-items-container{
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
}
.receipts-list-item {
    position: relative;
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.receipts-add-new-item-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 280px);
    overflow: auto;
}

.receipts-actions-add-list-item-footer-container {
    display: flex;
    width: 100%;
    height: 35px;
    text-align: center;
    align-items: center;
    justify-content: space-between; /* ✅ Ensures even spacing */
}

.receipts-actions-add-list-item-footer-dates-container {
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center; /* ✅ Centers icons within each container */
}

@media (max-width: 768px) {
    .receipts-list-container{
        width: 100%;
        height: calc(100vh - 150px);
    }
    .receipts-list-timetable-list-container {
        max-width: 100%;
    }
    .receipts-list-items-container{
        height: calc(100vh - 575px);
    }
    .receipts-add-new-item-container {
        height: calc(100vh - 300px);
    }
}