
.weather-list-container {
    width: 350px;
}
.weather-list-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 1;
}
.weather-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.weather-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.weather-list-timetable-list-container {
    padding-top: 50px;
    width: 350px;
    display: flex;
    overflow-x: auto; /* Corrected property */
    padding-bottom: 10px; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    white-space: nowrap; /* Corrected property */
}
.weather-list-items-container{
    padding-top: 50px;
    width: 350px;
    height: calc(100vh - 270px);
    overflow: auto;
}


@media (max-width: 768px) {
    .weather-list-container{
        width: 100%;
        height: calc(100vh - 150px);
    }
    .weather-list-timetable-list-container {
        max-width: 100%;
    }
    .weather-list-items-container{
        height: calc(100vh - 376px);
    }
}