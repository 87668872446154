.itinerary-list-container,
.itinerary-section-container {
    position: relative;
    width: 350px;
    height: calc(100vh - 210px);
}
.itinerary-list-header,
.itinerary-section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.itinerary-list-header-text,
.itinerary-section-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.itinerary-list-header-chip-holder,
.itinerary-section-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.itinerary-list-items-container,
.itinerary-section-items-container {

}
.itinerary-section-list-items-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 380px);
    overflow: auto;
}
.itinerary-section-container {

}
.itinerary-section-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.itinerary-section-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.itinerary-section-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

@media (max-width: 768px) {
    .itinerary-list-container{
        width: 100%;
        height: calc(100vh - 150px);
    }
    .itinerary-list-timetable-list-container {
        max-width: 100%;
    }
    .itinerary-section-list-items-container{
        height: calc(100vh - 300px);
    }
}